<template>
  <div class="view-turntable">
    <div class="turntable-box">
      <div
        class="turntable-row-box"
        v-for="(item, index) in joinList"
        :key="index"
        :class="[`flex${index}`]"
      >
        <div
          class="turntable-row-item"
          v-for="vItem in item"
          :key="vItem"
          :class="[(vItem * 1 + 1) % 2 == 0 ? 'one' : 'two']"
        >
          <img :src="list28[vItem]?.avatar" alt="" />
          <div class="float-turntable-row-item"></div>
        </div>
      </div>
      <div class="changetype point" @click="toupdata">
        <span v-show="!islucky">上期欧皇</span>
        <span v-show="islucky">本期转盘</span>
      </div>
      <div class="no">{{ date_no }}</div>
      <div class="centerbox">
        <div class="topbg">
          <div class="pricebox">
            <div>本期福利</div>
            <div><Cprice :price="bean" :size="0.3" /></div>
          </div>
        </div>
        <div class="bottombg" v-if="!islucky">
          <p class="timetext">开奖时间：</p>
          <el-statistic
            class="timenum"
            format="DD天HH小时mm分钟ss秒"
            :value="drawTime"
            time-indices
          >
          </el-statistic>
        </div>
        <div class="bottombg" v-else>
          <p class="timetext">开奖时间：</p>
          <div class="endtime">00天00小时00分钟00秒</div>
        </div>
      </div>
    </div>
    <div class="btnbox">
      <div class="dz">
        <div class="btncenter">
          <div class="tipsbox point" @click="replay" v-if="islucky">
            <img src="../../assets/images/kaijiang/replay.png" alt="" />
            重播动画
          </div>
          <div class="allbtn">
            <div v-if="!islucky">
              <div v-if="!this.joinstate">
                <div
                  class="joinbox point j1"
                  v-if="user.anchor == 0"
                  @click="tojoin"
                >
                  <div style="width: 100%; text-align: center; height: 0.35rem">
                    立即参与
                  </div>
                  <span class="usertext">参与本期需要{{ userpoint }}积分</span>
                </div>
                <div
                  class="joinbox point j1"
                  v-if="user.anchor == 1"
                  @click="tojoin"
                >
                  立即参与
                </div>
              </div>
              <div v-else>
                <div class="joinbox j2">已参与</div>
              </div>
            </div>
            <div v-else>
              <div v-if="this.luckyjoinstate">
                <div
                  class="joinbox point j1"
                  @click="showluckybean"
                  v-if="user.inviter_id == luckyid"
                >
                  已中奖
                </div>
                <div class="joinbox point j3" v-else>未中奖</div>
              </div>
              <div v-else>
                <div class="joinbox j3">未参与</div>
              </div>
            </div>

            <div
              class="tipsbox point"
              @click="
                () => {
                  this.showtips = !this.showtips;
                }
              "
            >
              <img src="../../assets/images/kaijiang/tips.png" alt="" />
              玩法说明
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="turntable-btns"></div>
    <div class="listtitle">上榜主播排名</div>
    <div class="listbox">
      <div class="toplist">
        <div class="toplistitem" v-for="(item, index) in 6" :key="item">
          <!-- 显示每个盒子的相应项目 -->
          <div class="items">
            <div class="itemstitle" :class="`t${item}`"></div>
            <div class="userbox">
              <img :src="toplist[index]?.user.avatar || noimg" alt="" />
            </div>
            <div class="username">
              {{ toplist[index]?.user.name || "虚位以待" }}
            </div>
            <div class="usernum" v-if="toplist[index]?.users_num">
              {{ toplist[index]?.users_num }}人
            </div>
          </div>
        </div>
      </div>
      <div class="elselist">
        <div class="elsetitle">
          <div class="lline"></div>
          <div class="titletext">其他主播排名</div>
          <div class="rline"></div>
        </div>
        <div class="elselistbox" v-if="elselist.length > 0">
          <div
            class="elseitem"
            :class="[(index * 1 + 1) % 2 == 0 ? 'e1' : 'e2']"
            v-for="(item, index) in elselist"
            :key="item.id"
          >
            <div class="numbox">
              {{ index + 6 }}
            </div>
            <div class="userimgbox">
              <img :src="item.user.avatar" alt="" />
            </div>
            <div class="ename">item.user.name</div>
            <div class="userjoinnum">{{ item.users_num }}人</div>
          </div>
        </div>
        <div class="nulldata" v-else>
          <div class="imgbox">
            <img src="@/assets/images/NewUser/pc-newuser-nulldata.png" alt="" />
            <div class="nulltext">暂无内容</div>
          </div>
        </div>
      </div>
    </div>

    <van-overlay @mousewheel.prevent.native="() => {}" :show="windshow">
      <div class="wintips">
        <div class="content">
          <div class="windhead"></div>
          <div class="wincontent">
            <div><Cprice :price="getluckybean" :size="0.3" /></div>
            <img
              class="contentimg"
              src="../../assets/images/kaijiang/wincontent.png"
              alt=""
            />
          </div>
          <div class="winenter">
            <div class="winbtn point" @click="windshow = !windshow">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay @mousewheel.prevent.native="() => {}" :show="showtips">
      <div class="showtips">
        <div class="tipscontentbox">
          <div class="titlebox">
            <p>玩法说明</p>
            <img
              class="closeicon point"
              src="@/assets/images/mall/pc-mall-close.png"
              alt=""
              @click="
                () => {
                  this.showtips = !this.showtips;
                }
              "
            />
          </div>
          <div class="line"></div>
          <div class="contenttext">{{ data_text }}</div>
          <div class="enterbtnbox">
            <div
              class="btn point"
              @click="
                () => {
                  this.showtips = !this.showtips;
                }
              "
            >
              确定
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Cprice from "../../components/common/Cprice.vue";
import {
  getturntabledetailApi,
  jointurntableApi,
  up_awardApi,
  getturntableApi,
} from "../../network/api";
import { mapState } from "vuex";
export default {
  components: { Cprice },
  data() {
    return {
      joinList: [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [10, 11, 12, 13],
        [14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        [24, 25, 26, 27],
      ],
      activeIndex: 0,
      officialRatioList: [1, 4, 9, 14, 18, 22, 25],
      mockData: {
        official_ratio: "25",
        list: [],
      },

      luckindex: null,

      foritemindex: null,
      drawTime: null,
      countdownInterval: null,

      listdata: {}, //储存箱子信息
      // 上期信息
      luckyid: null,
      islucky: false,

      bean: "",
      userpoint: "",
      joinstate: null,
      luckyjoinstate: null,
      getluckybean: "",

      windshow: false,

      date_no: "", //转盘期号
      data_id: "", //转盘id
      data_text: "", //转盘玩法说明
      isplay: false,
      clicktype: true, //防止点击过快

      toplist: [], //前六主播
      elselist: [], //其余主播
      noimg: require("../../assets/images/kaijiang/noimg.png"),

      showtips: false,
    };
  },
  created() {
    this.getdetaildata();
  },
  mounted() {
    this.foritem();
    this.countdownInterval = setInterval(this.updateCountdown, 1000);
    setTimeout(() => {
      document.querySelector(".out-bj").scrollIntoView({ block: "start" });
    }, 200);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
  methods: {
    totop() {
      document.querySelector(".out-bj").scrollIntoView({ block: "start" });
    },
    //展示中奖弹窗
    showluckybean() {
      this.windshow = true;
    },
    replay() {
      if (this.clicktype) {
        this.totop();
        this.clicktype = false;
        clearInterval(this.foritemindex);
        this.toopen(this.luckyid);
      } else {
        this.$message.error("你点的太快了");
      }
    },

    // 获得上期数据
    async toupdata() {
      if (!this.isplay) {
        this.islucky = !this.islucky;
        const list = document.querySelectorAll(".float-turntable-row-item");
        for (let i = 0; i < list.length; i++) {
          if (list[i].style.opacity == 1) {
            list[i].style.opacity = 0;
          }
        }
        // 切换到上期福利
        if (this.islucky) {
          clearInterval(this.foritemindex);
          clearInterval(this.countdownInterval);
          const res = await up_awardApi();
          if (res.data.code == 200) {
            this.mockData.list = res.data.data.top_list;
            this.elselist = res.data.data.residue_list;
            this.toplist = res.data.data.top_list;
            this.luckyid = res.data.data.data.pid;
            this.bean = res.data.data.data.total_bean;
            this.luckyjoinstate = res.data.data.is_join;
            this.getluckybean = res.data.data.data.bean;
            this.date_no = res.data.data.data.date_no;
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          this.getdetaildata();
          this.foritem();
        }
      } else {
        this.$message.error("动画正在播放");
      }
    },

    async tojoin() {
      if (this.clicktype) {
        this.clicktype = false;
        setTimeout(() => {
          this.clicktype = true;
        }, 4000);
        let params = {
          id: this.data_id,
        };
        const res = await jointurntableApi(params);
        if (res.data.code == 200) {
          this.getdetaildata();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
      } else {
        this.$message.error("你点的太快了");
      }
    },
    // 获得本期的数据
    async getdetaildata() {
      const reslistdata = await getturntableApi();
      this.listdata = reslistdata.data.data;
      this.userpoint = this.listdata.score;
      this.bean = this.listdata.bean;
      this.date_no = this.listdata.date_no;
      this.data_id = this.listdata.id;
      this.data_text = this.listdata.description;
      this.drawTime = new Date(this.listdata.end_time).getTime();
      // this.drawTime = new Date("2024-09-02 13:19:00").getTime();
      this.updateCountdown();
      let params = {
        date_no: this.date_no,
      };
      const res = await getturntabledetailApi(params);
      if (res.data.code == 200) {
        this.joinstate = res.data.data.is_join;
        this.mockData.list = res.data.data.top_list;
        this.toplist = res.data.data.top_list;
        this.elselist = res.data.data.residue_list;
      } else {
        this.$message.error(res.data.message);
      }
    },
    updateCountdown() {
      const now = new Date().getTime();
      const timeRemaining = this.drawTime - now;
      // 倒计时结束调用开奖接口
      if (timeRemaining < 0) {
        setTimeout(() => {
          this.getluckydata();
        }, 3000);
        clearInterval(this.countdownInterval);
      }
    },
    async getluckydata() {
      const res = await up_awardApi();
      if (res.data.code == 200) {
        this.luckyid = res.data.data.data.pid;
        this.toopen(this.luckyid);
      } else {
        this.$message.error(res.data.message);
      }
    },
    toopen(v) {
      clearInterval(this.foritemindex);
      this.luckindex = this.getFirstIndexForUser(v);
      this.activeIndex = 0;
      const list = document.querySelectorAll(".float-turntable-row-item");
      for (let i = 0; i < list.length; i++) {
        if (list[i].style.opacity == 1) {
          list[i].style.opacity = 0;
        }
      }
      this.init();
    },
    // 找到中奖的下标
    getFirstIndexForUser(userId) {
      // 存储所有符合条件的下标
      const matchingIndices = [];
      // 遍历 list28
      for (let i = 0; i < this.list28.length; i++) {
        const item = this.list28[i];
        // 检查当前元素是否符合条件
        if (item) {
          if (item.id === userId) {
            // 将符合条件的下标存储到数组中
            matchingIndices.push(i);
          }
        }
      }
      // 如果找到了符合条件的下标
      if (matchingIndices.length > 0) {
        // 随机选择一个下标
        // const randomIndex = Math.floor(Math.random() * matchingIndices.length);
        return matchingIndices[1];
      } else {
        return matchingIndices[0];
      }
    },

    init() {
      this.isplay = true;
      const list = document.querySelectorAll(".float-turntable-row-item");
      const initialIntervalTime = 70; // 初始转动速度（时间间隔）
      const finalIntervalTime = 200; // 最终转动速度（时间间隔）
      const fadeTime = 50; // 淡出时间
      const totalItems = list.length; // 获取项目总数

      // 计算从当前 activeIndex 到中奖索引 luckindex 所需的步数
      const stepsToLuckIndex =
        (this.luckindex - this.activeIndex + totalItems) % totalItems;

      // 设定转多少圈
      const circlesToSpin = 2; // 可以根据需求调整这个值
      const totalSteps = circlesToSpin * totalItems + stepsToLuckIndex;

      const decelerationStart = 5; // 从倒数第10步开始减速
      let currentStep = 0; // 当前步数
      let currentIntervalTime = initialIntervalTime; // 当前时间间隔

      // 渲染每一帧
      const renderFrame = () => {
        // 设置当前项的透明度
        let num = this.activeIndex;
        list[num].style.opacity = 1;
        setTimeout(() => {
          list[num].style.opacity = 0;
        }, fadeTime);

        // 更新 activeIndex
        if (this.activeIndex < totalItems - 1) {
          this.activeIndex += 1;
        } else {
          this.activeIndex = 0;
        }

        // 逐渐增加时间间隔以减慢转动速度
        if (currentStep >= totalSteps - decelerationStart) {
          // 开始减速
          const stepsRemaining = totalSteps - currentStep;
          const stepProgress =
            (decelerationStart - stepsRemaining) / decelerationStart;
          currentIntervalTime = Math.max(
            initialIntervalTime +
              (finalIntervalTime - initialIntervalTime) * stepProgress,
            finalIntervalTime
          );
        }

        currentStep += 1;

        // 检查是否到达目标步数
        if (currentStep >= totalSteps) {
          // 停止动画并显示中奖项
          list[this.luckindex].style.opacity = 1;
          this.activeIndex = 0;
          this.isplay = false;
          this.clicktype = true;
          if (!this.islucky) {
            setTimeout(() => {
              this.getdetaildata();
              this.foritem();
            }, 1000);
          }
        } else {
          // 使用 setTimeout 进行下一帧渲染
          setTimeout(() => {
            requestAnimationFrame(renderFrame);
          }, currentIntervalTime);
        }
      };

      // 开始动画
      requestAnimationFrame(renderFrame);
    },
    //进入随机旋转
    foritem() {
      const list = document.querySelectorAll(".float-turntable-row-item");
      this.foritemindex = setInterval(() => {
        let num = this.activeIndex;
        list[num].style.opacity = 1;
        setTimeout(() => {
          list[num].style.opacity = 0;
        }, 200);
        if (this.activeIndex < 27) {
          this.activeIndex += 1;
        } else {
          this.activeIndex = 0;
        }
      }, 200);
    },
  },
  computed: {
    ...mapState(["user"]),
    list28() {
      const resultList = new Array(28).fill(null);
      const officialUser = {
        id: 0,
        name: "官方",
        avatar:
          "https://api.tmskins.com/uploads/images/c712335ec0ba72b953ab25795e60383b.jpg",
      };
      // 填充官方用户
      this.officialRatioList.forEach((index) => {
        resultList[index] = officialUser;
      });

      let filledSlots = 0;
      let zeroRatioUserAdded = false; // 标记是否已添加比例为0的用户
      this.mockData.list.forEach((item) => {
        if (item.ratio == 0 && !zeroRatioUserAdded) {
          // 查找resultList中第一个null元素的索引
          const availableIndex = resultList.findIndex((slot) => !slot);
          if (availableIndex !== -1) {
            resultList[availableIndex] = item.user;
            filledSlots++;
            zeroRatioUserAdded = true; // 标记已添加
          }
        } else if (item.ratio > 0) {
          // 根据用户比例计算应该填充到resultList中的用户数量（四舍五入到最接近的整数）
          const num = Math.round((item.ratio / 100) * 28);
          // 循环，尝试将用户填充到resultList中
          for (let i = 0; i < num && filledSlots < 28; i++) {
            const availableIndex = resultList.findIndex((slot) => !slot);
            if (availableIndex !== -1) {
              resultList[availableIndex] = item.user;
              filledSlots++;
            }
          }
        }
      });

      return resultList;
    },
  },
};
</script>

<style lang="scss">
.view-turntable {
  width: 100%;
  height: 100%;
  background: rgba(7, 7, 7, 0.7);
  padding-top: 0.34rem;
  .turntable-box {
    width: 11.7rem;
    height: 8.26rem;
    display: flex;
    position: relative;
    margin: 0 auto;
    background: url("../../assets/images/kaijiang/kjzpbg.png") no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    // flex-wrap: wrap;
    .centerbox {
      width: 8.6rem;
      height: 4.12rem;
      position: absolute;
      top: 30.5%;
      left: 13.25%;
      .topbg {
        width: 100%;
        height: 3.4rem;
        background: url("../../assets/images/kaijiang/kjzjbg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 1.5rem;
        .pricebox {
          height: 0.8rem;
          text-align: center;
          line-height: 0.4rem;
          font-weight: 500;
          font-size: 0.24rem;
          color: #ffffff;
        }
      }
      .bottombg {
        width: 100%;
        height: 0.62rem;
        background: url("../../assets/images/kaijiang/timebg.png") no-repeat;
        background-size: 100% 100%;
        margin: 0.11rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 0.24rem;
        color: #ffffff;
        .timetext {
          font-weight: 500;
          font-size: 0.24rem;
          color: #ffffff;
        }
        .timenum {
          width: unset;
        }
        .timenum .con .number {
          width: unset;
          color: #fff !important;
          font-weight: 500 !important;
          font-size: 0.24rem !important;
          line-height: 0.26rem !important;
          height: 0.26rem !important;
        }
        .endtime {
          width: unset;
          color: #fff !important;
          font-weight: 500 !important;
          font-size: 0.24rem !important;
          line-height: 0.26rem !important;
          height: 0.26rem !important;
          padding: 0 0.04rem;
        }
      }
    }
    .changetype {
      position: absolute;
      top: 0.5rem;
      background: url("../../assets/images/kaijiang/sqbg.png") no-repeat;
      background-size: 100% 100%;
      width: 1.63rem;
      height: 0.58rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 0.2rem;
      color: #c5c0ff;
      padding: 0 0.2rem 0.13rem 0;
    }
    .no {
      position: absolute;
      right: 0.2rem;
      top: 0.35rem;
      width: 2.7rem;
      height: 0.7rem;
      background: url("../../assets/images/kaijiang/qhbg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(10deg);
      font-weight: 500;
      font-size: 0.24rem;
      color: #c5c0ff;
      padding-bottom: 0.1rem;
      padding-right: 0.1rem;
    }
    .turntable-row-box {
      display: flex;

      .turntable-row-item {
        width: 1.1rem;
        height: 1.1rem;
        // background: pink;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.72rem;
          border-radius: 50%;
        }
        .float-turntable-row-item {
          width: 1.45rem;
          height: 1.45rem;
          position: absolute;
          background: url("../../assets/images/kaijiang/zjgz.png") no-repeat;
          background-size: 100% 100%;
          opacity: 0;
          z-index: 1;
        }
      }
    }
    .turntable-row-box:nth-of-type(1) {
      display: flex;
      position: absolute;
      left: 0.35rem;
      top: 1.3rem;
    }
    .turntable-row-box:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0.35rem;
      top: 2.4rem;
    }
    .turntable-row-box:nth-of-type(3) {
      display: flex;
      position: absolute;
      flex-direction: row-reverse;
      right: 0.35rem;
      bottom: 0.35rem;
    }
    .turntable-row-box:nth-of-type(4) {
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      left: 0.35rem;
      bottom: 1.45rem;
    }
  }
  .btnbox {
    width: 100%;
    height: 3.2rem;
    position: absolute;
    top: 10.5rem;
    .dz {
      width: 10.89rem;
      height: 100%;
      background: url("../../assets/images/kaijiang/kjdz.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .btncenter {
        width: 8.6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tipsbox {
          width: 1.62rem;
          height: 0.6rem;
          background: url("../../assets/images/kaijiang/kjwfsm.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 0.2rem;
          color: #ffffff;
          img {
            width: 0.28rem;
            margin-right: 0.05rem;
          }
        }
        .allbtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .joinbox {
          width: 4.12rem;
          height: 1.04rem;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 0.44rem;
          color: #ff7a00;
          text-shadow: -0.01rem 0.01rem 0 #ffffff, -0.01rem -0.01rem 0 #ffffff,
            0.01rem -0.01rem 0 #ffffff, -0.01rem 0.01rem 0 #ffffff; /* 描边颜色 */
          margin: 0 0.6rem;
          text-align: center;
          padding-bottom: 0.1rem;
        }
        .j1 {
          background: url("../../assets/images/kaijiang/joinbtn1.png") no-repeat;
          background-size: 100% 100%;
        }
        .j2 {
          background: url("../../assets/images/kaijiang/joinbtn2.png") no-repeat;
          background-size: 100% 100%;
          color: #ff9d43;
        }
        .j3 {
          background: url("../../assets/images/kaijiang/joinbtn3.png") no-repeat;
          background-size: 100% 100%;
          color: #868686;
        }
        .usertext {
          font-family: PingFang SC, PingFang SC;
          display: block;
          font-weight: 600;
          font-size: 0.18rem;
          color: #ff7a00;
          height: 0.25rem;
          text-shadow: unset;
        }
      }
    }
  }
  .turntable-btns {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
  .listtitle {
    position: absolute;
    top: 14.15rem;
    right: 42.25%;
    width: 2.95rem;
    height: 0.6rem;
    margin: 0 auto;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI;
    font-weight: bold;
    font-size: 0.36rem;
    color: #ffffff;
    line-height: 0.6rem;
    text-shadow: 0 0.01rem 0.01rem rgba(0, 0, 0, 0.5);
    text-align: center;
    background: url("../../assets/images/kaijiang/listtitlebg.png") no-repeat;
    background-size: 100% 100%;
  }
  .listbox {
    width: 12.3rem;
    background: url("../../assets/images/kaijiang/listbg.png") no-repeat;
    background-size: 100% 100%;
    min-height: 16rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.2rem;

    .toplist {
      width: 9.6rem;
      height: 6.65rem;
      margin: 1.13rem auto 0.4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .toplistitem {
        width: 3.1rem;
        height: 3.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .items {
          width: 2.4rem;
          height: 3rem;
          position: relative;
          background: url("../../assets/images/kaijiang/toplistitem.png")
            no-repeat;
          background-size: 100% 100%;
          .itemstitle {
            width: 1.2rem;
            height: 0.4rem;
            position: absolute;
            top: -0.15rem;
            right: 25%;
          }
          .t1 {
            background: url("../../assets/images/kaijiang/t1.png") no-repeat;
            background-size: 100% 100%;
          }
          .t2 {
            background: url("../../assets/images/kaijiang/t2.png") no-repeat;
            background-size: 100% 100%;
          }
          .t3 {
            background: url("../../assets/images/kaijiang/t3.png") no-repeat;
            background-size: 100% 100%;
          }
          .t4 {
            background: url("../../assets/images/kaijiang/t4.png") no-repeat;
            background-size: 100% 100%;
          }
          .t5 {
            background: url("../../assets/images/kaijiang/t5.png") no-repeat;
            background-size: 100% 100%;
          }
          .t6 {
            background: url("../../assets/images/kaijiang/t6.png") no-repeat;
            background-size: 100% 100%;
          }
          .userbox {
            width: 100%;
            height: 1.72rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 1.06rem;
              height: 1.06rem;
              border-radius: 50%;
              background: #ae9fc5;
              border-radius: 50%;
              padding: 0.02rem;
            }
          }
          .username {
            width: 1.56rem;
            height: 0.32rem;
            line-height: 0.32rem;
            font-weight: 400;
            font-size: 0.2rem;
            color: #ffffff;
            margin: 0 auto;
            text-align: center;
            border: 0.01rem solid transparent;
            border-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)
              )
              1 1;
          }
          .usernum {
            width: 100%;
            margin-top: 0.16rem;
            text-align: center;
            font-weight: 400;
            font-size: 0.2rem;
            color: #ffffff;
            line-height: 0.2rem;
          }
        }
      }
    }
    .elselist {
      width: 10.58rem;
      margin: 0 auto;
      .elsetitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .lline {
          width: 3.9rem;
          height: 0;
          border: 0.02rem solid;
          border-image: linear-gradient(
              256deg,
              rgba(144, 142, 193, 1),
              rgba(144, 142, 193, 0)
            )
            2 2;
        }
        .rline {
          width: 3.9rem;
          height: 0;
          border: 0.02rem solid;
          border-image: linear-gradient(
              90deg,
              rgba(144, 142, 193, 1),
              rgba(144, 142, 193, 0)
            )
            2 2;
        }
        .titletext {
          font-weight: 400;
          font-size: 0.32rem;
          color: #ffffff;
        }
      }
      .elselistbox {
        width: 100%;
        height: 6.28rem;
        overflow-y: scroll;
        margin-top: 0.4rem;
        .elseitem {
          width: 10.52rem;
          height: 0.76rem;
          margin-bottom: 0.2rem;
          border: 0.01rem solid #b19bd6;
          display: flex;
          align-items: center;
          .numbox {
            width: 1.1rem;
            text-align: center;
            line-height: 0.76rem;
            font-weight: 400;
            font-size: 0.32rem;
            color: #ffffff;
          }
          .userimgbox {
            width: 1rem;
            height: 0.76rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 0.58rem;
              height: 0.58rem;
              border-radius: 50%;
            }
          }
          .ename {
            width: 6rem;
            font-weight: 400;
            font-size: 0.24rem;
            color: #ffffff;
            margin-left: 0.3rem;
          }
          .userjoinnum {
            font-weight: 400;
            font-size: 0.24rem;
            color: #ffffff;
          }
        }
        .e1 {
          background: #211c2c;
        }
        .e2 {
          background: #020315;
        }
      }
      /* 对于 WebKit 浏览器（如 Chrome 和 Safari） */
      .elselistbox::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
      }

      /* 对于 Firefox 浏览器 */
      .elselistbox {
        scrollbar-width: none; /* 隐藏滚动条 */
      }

      /* 对于 IE 和 Edge 浏览器 */
      .elselistbox {
        -ms-overflow-style: none; /* 隐藏滚动条 */
      }
      .nulldata {
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .imgbox {
          width: 1.26rem;
          height: 1.26rem;
          img {
            width: 100%;
          }
          .nulltext {
            text-align: center;
            font-size: 0.18rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c5bbed;
          }
        }
      }
    }
  }

  .wintips {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 395;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 5.1rem;
      .windhead {
        width: 100%;
        height: 0.64rem;
        background: url("../../assets/images/kaijiang/wintitle.png") no-repeat;
        background-size: 100% 100%;
      }
      .wincontent {
        width: 100%;
        height: 3.75rem;
        .contentimg {
          height: 3.8rem;
        }
      }
      .winenter {
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .winbtn {
          width: 2.66rem;
          height: 0.64rem;
          background: url("../../assets/images/kaijiang/winenter.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.24rem;
          color: #9c5800;
        }
      }
    }
  }
  .showtips {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 395;
    display: flex;
    justify-content: center;
    align-items: center;
    .tipscontentbox {
      width: 7.6rem;
      height: 2.6rem;
      background: rgba(8, 8, 30, 0.8);
      border-radius: 0.04rem;
      border: 0.02rem solid #5d5d9b;
      .titlebox {
        width: 100%;
        height: 0.51rem;
        line-height: 0.51rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.2rem;
        color: #ffffff;
        text-align: center;
        position: relative;
        .closeicon {
          width: 0.16rem;
          height: 0.16rem;
          position: absolute;
          right: 0.15rem;
          top: 0.15rem;
        }
      }
      .line {
        width: 100%;
        height: 0;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .contenttext {
        width: 6.5rem;
        height:1rem;
        margin: 0.12rem auto;
        font-weight: 400;
        font-size: 0.16rem;
        color: #bfbfed;
      }
      .enterbtnbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 0.3rem;
        .btn {
          width: 1.1rem;
          height: 0.32rem;
          border-radius: 0.02rem;
          text-align: center;
          line-height: 0.32rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.16rem;
          color: #ffffff;
        }
      }
    }
  }
}
.one {
  background: url("../../assets/images/kaijiang/kjgez2.png") no-repeat;
  background-size: 100% 100%;
}
.two {
  background: url("../../assets/images/kaijiang/kjgez1.png") no-repeat;
  background-size: 100% 100%;
}
</style>
